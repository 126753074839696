<template>
  <NotFound />
</template>
<script>
import NotFound from "./NotFound.vue"
export default {
  name: "RouteHub",
  components: {
    NotFound,
  },
  created() {
    if (this.$route.query.id) {
      const { id, ...other_query } = this.$route.query
      const path = this.pathMapping(id, other_query)
      if (!path) {
        return
      }
      this.$router.push({
        path: `/${path}`,
        query: other_query,
      })
    }
  },
  methods: {
    pathMapping(id, other_query) {
      const path_infos = [
        { path: "reception", id: "w3sbc3an" },
        { path: "user/regist", id: "ei9p5uee" },
        { path: "user/edit", id: "mybey2sb" },
        { path: "locker/pre-regist", id: "y9z5c8de" },
        { path: "payment-reception-failed", id: "s84ixvz5" },
        { path: "payment-reception-complete", id: "x7ckua5f" },
        { path: "reception/gmo-payment/:order_no/cancel", id: "y7bx5srq", params: [
          "order_no",
        ]},
      ]

      const selected = path_infos.find((path_info) => {
        return path_info.id === id
      })

      if (selected.params) {
        let path = selected.path
        selected.params.forEach(param => {
          const reg = new RegExp(`:${param}`)
          path = path.replace(reg, other_query[`${param}`])
        })
        return path
      }
      return selected.path
    },
  },
}
</script>
