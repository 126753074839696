<template>
  <article class="message is-warning is-small">
    <div class="message-body">
      ページが見つかりません。最初から操作を行ってください。
    </div>
  </article>
</template>

<script>
export default {
  name: "NotFound",
}
</script>
